import { useState } from "react";
import { predict } from "@privateid/cryptonets-web-sdk";
import { getStatusMessage } from "@privateid/cryptonets-web-sdk/dist/utils";

// Type definitions for the callback result
type PredictResult = {
  guid?: string;
  puid?: string;
  antispoof_status: number;
  face_validation_status: number;
  mf_token?: string;
};

// Type definition for the hook's parameters
interface UseMultiframePredictProps {
  onSuccess: () => void;
}

let loop = true;

const useMultiframePredict = ({ onSuccess }: UseMultiframePredictProps) => {
  const [multiframePredictMessage, setPredictMessage] = useState<string>("");
  const [multiframePredictAntispoofPerformed, setPredictAntispoofPerformed] =
    useState<string>("");
  const [multiframePredictAntispoofStatus, setPredictAntispoofStatus] =
    useState<number | null>();
  const [multiframePredictValidationStatus, setPredictValidationStatus] =
    useState<number | null>();
  const [progress, setProgress] = useState<number>(0);
  const [multiframePredictGUID, setPredictGUID] = useState<string>("");
  const [multiframePredictPUID, setPredictPUID] = useState<string>("");

  // Type definition for the callback function
  const callback = async (result: PredictResult) => {
    console.log("predict callback hook result:", result);
    const token = result.mf_token;
    if (token) {
      setProgress((p) => {
        if (p >= 100) return 100;
        return p + 20;
      });
    } else {
      setProgress(0);
    }
    if (result.guid && result.puid) {
      setPredictGUID(result.guid);
      setPredictPUID(result.puid);
      setPredictAntispoofStatus(result.antispoof_status);
      setPredictValidationStatus(result.face_validation_status);
      setPredictMessage(getStatusMessage(result.face_validation_status));
      onSuccess();
    } else {
      setPredictAntispoofStatus(result.antispoof_status);
      setPredictValidationStatus(result.face_validation_status);
      setPredictMessage(getStatusMessage(result.face_validation_status));
      if (loop) {
        multiframePredictUserOneFa({ mf_token: token });
      }
    }
  };

  // Type definition for the function parameters
  interface MultiframePredictUserOneFaProps {
    mf_token?: string;
  }

  const multiframePredictUserOneFa = async ({
    mf_token,
  }: MultiframePredictUserOneFaProps) => {
    setPredictAntispoofPerformed("");
    // setPredictAntispoofStatus(null);
    // setPredictValidationStatus(null);
    setPredictGUID("");
    setPredictPUID("");

    await predict({
      callback,
      config: {
        disable_predict_mf: false,
        mf_token: mf_token || "",
      },
    });
  };

  return {
    multiframePredictUserOneFa,
    multiframePredictMessage,
    multiframePredictAntispoofPerformed,
    multiframePredictAntispoofStatus,
    multiframePredictValidationStatus,
    multiframePredictGUID,
    multiframePredictPUID,
    progress,
  };
};

export default useMultiframePredict;
