import { useContext, useEffect, useState } from "react";
import useWasm from "hooks/useWasm";
import { isMobile } from "utils";
import {
  AUTHENTICATION_FAILED,
  CameraConfig,
  ENROLL_CANVAS_RESOLUTION,
} from "constant";
import useCamera from "hooks/useCamera";
import useCameraPermissions from "hooks/useCameraPermissions";
import PhoneInput from "common/components/phoneInput";
import useFaceLoginWithLivenessCheck from "hooks/useFaceLoginWithLiveness";
import { UserContext } from "context/userContext";
import { useNavigation } from "utils/onNavigate";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import FaceAnimation from "common/animateCircle/faceScanAnimation";
import MainLogo from "../../assets/login/logo-main.png";
import PhoneOtp from "./phoneScreen";

const ProveLoginOtp = () => {
  const context: any = useContext(UserContext);
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState<string>("");
  const [showPhone, setShowPhone] = useState(true);
  const { isCameraGranted }: any = useCameraPermissions(() => {});
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const [minimizeCamera, setMinimizeCamera] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isCompletedPredict, setIsCompletedPredict] = useState(false);
  const logo = context?.configuration?.logo || MainLogo;
  const { navigateToNextPage } = useNavigation();
  // Camera and Wasm init
  const onCameraFail = () => {
    //   cameraFail?.();
  };
  const cameraReady = () => {
    //   onCameraReady?.();
    faceLoginWithLiveness();
  };
  const documentScan = false;
  const { ready: wasmReady, wasmStatus } = useWasm();
  const canvasResolution =
    !isMobile && !documentScan ? ENROLL_CANVAS_RESOLUTION : null;
  const { ready, init, device, devices } = useCamera(
    CameraConfig?.elementId,
    documentScan ? "back" : (CameraConfig?.mode as any),
    CameraConfig?.requireHD,
    onCameraFail,
    documentScan,
    canvasResolution
  );
  const handleWasmLoad = () => {
    if (!wasmReady && wasmStatus.isChecking) return;
    if (wasmReady && !wasmStatus.isChecking && wasmStatus.support) {
      if (!ready) {
        init();
      } else if (isCameraGranted && ready) {
        cameraReady();
      }
    }
    if (!wasmReady && !wasmStatus.isChecking && !wasmStatus.support) {
      onCameraFail();
    }
  };
  useEffect(() => {
    if (step === 2) {
        handleWasmLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasmReady, ready, wasmStatus, step]);
  const onStatus = () => {};
  const onPredictFail = () => {};

  const {
    faceLoginWithLiveness,
    faceLoginWithLivenessMessage,
    faceLoginData,
    faceLoginResponseStatus,
    faceLoginInputImageData,
    loginGUID,
    loginPUID,
  } = useFaceLoginWithLivenessCheck(
    () => {},
    onStatus,
    50,
    false,
    onPredictFail
  );

  useEffect(() => {
    if (loginGUID && loginPUID) {
      setIsCompletedPredict(true);
    }
  }, [faceLoginWithLivenessMessage]);

  useEffect(() => {
    if (isCompletedPredict && !showPhone) {
      setCompleted(true);
      context.setUser({
        ...context.user,
        uuid: loginPUID,
        guid: loginGUID,
      });
      handelLoginResponse(faceLoginResponseStatus);
    }
  }, [isCompletedPredict, showPhone]);

  useEffect(() => {
    if (phone?.length >= 12) {
      setShowPhone(false);
    }
  }, [phone]);

  const handelLoginResponse = async (result: any) => {
    if ([0, 10].includes(result)) {
      localStorage.setItem("uuid", JSON.stringify(loginPUID || {}));
      setTimeout(() => {
        navigateToNextPage("", "/", "/login-mobile-otp");
      }, 2000)
    } else {
      context.setFailedMessage(AUTHENTICATION_FAILED);
      navigateWithQueryParams("/failed");
    }
  };

  const handleAnimationComplete = (state: string) => {
    if (state === "start") {
      // setStartAnimation(true);
    } else if (state === "completed") {
      setCompleted(true);
      context.setUser({
        ...context.user,
        uuid: loginPUID,
        guid: loginGUID,
      });
      handelLoginResponse(faceLoginResponseStatus);
    }
  };

  const renderScreens = () => {
    switch (step) {
      case 1:
        return (
            <PhoneOtp setStep={setStep} />
        )
        break;
      case 2:
        return (
            <div className="bg-[#000] w-[100%] absolute overflow-hidden top-[0px] left-[0px] max-md:w-[100%] h-[100vh] max-md:top-[0px] max-md:h-[calc(100vh_-80px)] flex flex-col items-center justify-center">
            {showPhone && (
              <div className="absolute top-[0px] left-[0px] z-[99999] bg-[#0000006e] backdrop-blur-md h-[100%] w-[100%] rounded-[2px] flex items-center justify-center">
                <div className="relative w-[90%] max-md:w-[90%]">
                  <div className="mt-[-20px] provePhoneInput">
                    <PhoneInput
                      setValue={setPhone}
                      placeholder={"Phone Number"}
                      ariaLabel={"Phone Number"}
                    />
                  </div>
                  <div
                    onClick={() => {
                      setShowPhone(false);
                    }}
                    className="bg-primary text-[#fff] w-[100px] h-[40px] mt-[10px] ml-auto rounded-[3px] flex justify-center items-center cursor-pointer text-[15px]"
                  >
                    Sign in
                  </div>
                </div>
              </div>
            )}
            {!completed && (
              <img
                src={logo}
                alt="Logo"
                className="w-[130px] absolute top-[10px] z-[99999] max-md:right-[40%] right-[unset] left-[40%] max-md:left-[unset]"
              />
            )}
  
            {completed ? (
              <div className="bg-[#cccccc80] w-[100%] h-[100%] flex items-center justify-center successAnimationNew">
                <div className="text-[20px] absolute top-[62px] left-[38px] z-[99999999] w-full bg-transparent bg-opacity-70 text-white text-left py-2 rounded-lg">
                  {"Done - Image Deleted "}
                </div>
                <div className="text-[14px] absolute top-[95px] left-[38px] z-[99999999] w-full bg-transparent bg-opacity-70 text-white text-left py-2 rounded-lg">
                  From now on you can use your
                  <br /> face to do things quicker
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="svg-success"
                  viewBox="0 0 24 24"
                >
                  <g
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                  >
                    <circle
                      className="success-circle-outline"
                      cx="12"
                      cy="12"
                      r="11.5"
                    />
                    <circle
                      className="success-circle-fill"
                      cx="12"
                      cy="12"
                      r="11.5"
                    />
                    <polyline
                      className="success-tick"
                      points="17,8.5 9.5,15.5 7,13"
                    />
                  </g>
                </svg>
              </div>
            ) : (
              <>
                <FaceAnimation
                  isCircle={minimizeCamera}
                  isScanned={completed}
                  handleAnimationComplete={handleAnimationComplete}
                  fullScreen={true}
                >
                  <video
                    id="userVideo"
                    muted
                    autoPlay
                    playsInline
                    className={`w-full h-full face-camera object-cover`}
                  />
                </FaceAnimation>
                <div className="text-[20px] absolute bottom-28 w-full bg-black bg-opacity-70 text-white text-center py-2 rounded-lg">
                  {faceLoginWithLivenessMessage}
                </div>
                <p className="text-[16px] absolute bottom-[10px] text-left px-[10px] z-[999999] text-[#fff]">
                  By clicking Sign-in you agree to our terms of use and that you
                  have read the Privacy Policy.{" "}
                </p>
              </>
            )}
          </div>
        )

      default:
        break;
    }
  };

  return (
    <div className="bg-[#0c121c] backdrop-blur-md max-md:h-[calc(100vh_-80px)] relative">
      <div className="max-md:w-[100%] w-[30%] m-auto h-[100vh] relative mb-[0px] max-md:h-[calc(100vh_-80px)]">
        {renderScreens()}
      </div>
    </div>
  );
};

export default ProveLoginOtp;
