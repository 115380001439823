import {
  getOrganizationFromURL,
  getProjectFromURL,
  hexToHSL,
  updatePrimaryColor,
} from "../utils";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { getOrganizationConfig } from "services/api";
import { Loader2 } from "lucide-react";
import { initializeBaseURL } from "services/orchestration";

export const UserContext = createContext({
  user: {},
  setUser: (userData: any) => {},
  tokenParams: "",
  setTokenParams: (token: any) => {},
  failedMessage: "",
  setFailedMessage: (message: any) => {},
  loginOption: "",
  setLoginOption: (message: any) => {},
  themeHhs: false,
  setThemeHhs: (hhs: any) => {},
  successMessage: "",
  setSuccessMessage: (message: any) => {},
  isWasmLoaded: false,
  setIsWasmLoaded: (isWasmLoaded: boolean) => {},
  configuration: {},
  setConfiguration: (config: any) => {},
  pageConfiguration: {},
  setPageConfiguration: (config: any) => {},
  pageIndex: 0,
  setPageIndex: (pageIndex: any) => {},
  pagePrevIndex: undefined,
  setPrevPageIndex: (pageIndex: any) => {},
  userRoutes: [],
  setUserRoutes: (pageIndex: any) => {},
  activeFlow: "",
  setActiveFlow: (pageIndex: any) => {},
  previousRoutes: [],
  setPreviousRoutes: (route: any) => {},
  age: "",
  setAge: (age: any) => {},
  checkoutCompleted: false,
  setCheckoutCompleted: (checkoutCompleted: any) => {},
  citizenship: "",
  setCitizenship: (age: any) => {},
  isFaceScan: false,
  setIsFaceScan: (age: any) => {},
  isGenerateUuid: false,
  setIsGenerateUuid: (isGenerateUuid: boolean) => {},
});

let loaded: boolean;

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState({});
  const [tokenParams, setTokenParams] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [loginOption, setLoginOption] = useState("");
  const [themeHhs, setThemeHhs] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isWasmLoaded, setIsWasmLoaded] = useState(false);
  const [configuration, setConfiguration] = useState<any>(null);
  const [pageConfiguration, setPageConfiguration] = useState<any>(null);
  const [pageIndex, setPageIndex] = useState<any>(0);
  const [pagePrevIndex, setPrevPageIndex] = useState<any>(undefined);
  const [userRoutes, setUserRoutes] = useState<any>([]);
  const [activeFlow, setActiveFlow] = useState<any>("");
  const [previousRoutes, setPreviousRoutes] = useState<any>([]);
  const [age, setAge] = useState<any>("");
  const [checkoutCompleted, setCheckoutCompleted] = useState<any>(false);
  const [citizenship, setCitizenship] = useState<any>("");
  const [isFaceScan, setIsFaceScan] = useState<any>(false);
  const [isGenerateUuid, setIsGenerateUuid] = useState<any>(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const values = {
    user,
    setUser,
    tokenParams,
    setTokenParams,
    failedMessage,
    setFailedMessage,
    loginOption,
    setLoginOption,
    themeHhs,
    setThemeHhs,
    successMessage,
    setSuccessMessage,
    isWasmLoaded,
    setIsWasmLoaded,
    configuration,
    setConfiguration,
    pageConfiguration,
    setPageConfiguration,
    pageIndex,
    setPageIndex,
    pagePrevIndex,
    setPrevPageIndex,
    userRoutes,
    setUserRoutes,
    activeFlow,
    setActiveFlow,
    previousRoutes,
    setPreviousRoutes,
    age,
    setAge,
    checkoutCompleted,
    setCheckoutCompleted,
    citizenship,
    setCitizenship,
    isFaceScan,
    setIsFaceScan,
    isGenerateUuid,
    setIsGenerateUuid,
  };
  useEffect(() => {
    const projectName = getProjectFromURL();
    if (projectName === "hhs") {
      setThemeHhs(true);
    }
  }, []);
  // Get current organization data from strapi.
  const onGetOrganizationConfig = async (organization: string) => {
    const isKantara = organization === "kantara";
    const organizationConfig: any = await getOrganizationConfig(organization);
    if (organizationConfig?.name) {
      if (organization !== "airtel") {
        initializeBaseURL(
          organizationConfig?.orchestrationUrl ||
            organizationConfig?.env?.REACT_APP_ORCHESTRATION_API_URL
        );
      }
      const hsl = hexToHSL(organizationConfig?.primaryColor);
      updatePrimaryColor(`${hsl?.h},${hsl?.s}%,${hsl?.l}%`);
      setConfiguration({ ...organizationConfig, isKantara });
    } else {
      setConfiguration({});
    }
  };
  useEffect(() => {
    if (!loaded) {
      const projectName = getOrganizationFromURL();
      loaded = true;
      if (projectName) {
        onGetOrganizationConfig(projectName);
      } else {
        setConfiguration({});
      }
    }
  }, [loaded]);
  const memoValues = useMemo(() => values, [values]);
  if (!configuration)
    return (
      <div className="absolute h-full w-full left-0 top-0 z-50 flex items-center justify-center">
        <Loader2 className="animate-spin h-[55px] w-[55px] text-[#000]" />
      </div>
    );
  return (
    <UserContext.Provider value={memoValues}>{children}</UserContext.Provider>
  );
};

export default UserContextProvider;
