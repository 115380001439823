import lock from "assets/lock.svg";
import useCameraPermissions from "hooks/useCameraPermissions";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "context/userContext";
import useMultiframePredict from "hooks/useMultiFrameFaceLogin";
import { getUser } from "services/api";
import {
  ACCOUNT_NOT_APPROVED,
  AUTHENTICATION_FAILED,
  ERROR,
  SECURITY_CHALLENGE,
  SUCCESS,
} from "constant";
import { extractRoute, getOrganizationFromURL, getStatusFromUser } from "utils";
import Layout from "common/layout";
import BackButton from "common/components/backButton";
import CameraComponent from "common/components/camera";
import { useNavigation } from "utils/onNavigate";
import {
  generateAuthenticationOptions,
  verifyAuthentication,
} from "services/passkey";
import { startAuthentication } from "@simplewebauthn/browser";
import { useToast } from "components/ui/use-toast";
import PhoneInput from "common/components/phoneInput";
import { Check } from "lucide-react";

function MultiFaceLogin() {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const projectName = getOrganizationFromURL();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const { isCameraGranted } = useCameraPermissions(() => {});
  const [completed, setCompleted] = useState(false);
  const [phone, setPhone] = useState<string>("");
  const [showPhone, setShowPhone] = useState(true);
  const onStatus = () => {};
  const onPredictFail = () => {
    if (context?.configuration?.isKantara) {
      navigateToNextPage("", "/predict-fail", "/predict-scan");
    }
  };
  const { toast } = useToast();

  const {
    multiframePredictUserOneFa: faceLoginWithLiveness,
    multiframePredictMessage: faceLoginWithLivenessMessage,
    multiframePredictValidationStatus: faceLoginResponseStatus,
    multiframePredictGUID: loginGUID,
    multiframePredictPUID: loginPUID,
    progress,
  } = useMultiframePredict({ onSuccess: () => {} });

  const onSuccess = () => {
    context.setUser({
      ...context.user,
      uuid: loginPUID,
      guid: loginGUID,
    });
    handelLoginResponse(faceLoginResponseStatus);
  };

  const loadPageConfiguration = () => {
    const currentRouteIndex = context?.configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === "/"
    );
    const activeIndex = currentRouteIndex;
    context?.setPageIndex(activeIndex);
    const pageConfiguration = extractRoute(context?.configuration, activeIndex);
    context?.setPageConfiguration(pageConfiguration);
  };

  const onFailPasskey = () => {
    localStorage.removeItem("uuid");
    localStorage.removeItem("user");
    toast({
      variant: "destructive",
      description: "There was some issue authenticating with passkey.",
    });
    loadPageConfiguration();
    navigateToNextPage("", "/", "/login-dl");
  };

  const onAuthenticatePasskey = async () => {
    try {
      const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
      const response = await generateAuthenticationOptions(uuid);
      if (response?.challenge) {
        let asseResp;
        try {
          asseResp = await startAuthentication(response);
        } catch (error) {
          console.log({ error });
        }
        const verificationJSON = await verifyAuthentication({ asseResp, uuid });
        const alreadyEnroll =
          verificationJSON?.verified &&
          context?.user?.alreadyEnrolled &&
          pageConfig?.type === "enrollPasskey";
        if (verificationJSON?.verified) {
          context.setCheckoutCompleted(true);
          context.setSuccessMessage("Success! Your account is created");
          if (alreadyEnroll) {
            context.setLoginOption("checkoutVc");
            context?.setSuccessMessage("Authentication successful!");
            navigateToNextPage("", "/vc-proof", "/login-dl");
          } else if (context?.configuration?.isKantara) {
            navigateToNextPage();
          } else if (pageConfig) {
            context?.setSuccessMessage("Authentication successful!");
            navigateToNextPage("", "/vc-proof", "/generate-passkey");
          } else {
            navigateToNextPage("", "/vc-proof", "/generate-passkey");
          }
        } else {
          onFailPasskey();
        }
      } else {
        onFailPasskey();
      }
    } catch (e) {
      onFailPasskey();
    }
  };

  const handelLoginResponse = async (result: any) => {
    if ([0, 10].includes(result)) {
      localStorage.setItem("uuid", JSON.stringify(loginPUID || {}));
      const payload = {
        guid: loginGUID,
        uuid: loginPUID,
        checkVC: false,
      };
      // This is for kantara flow
      if (
        context?.configuration?.isKantara &&
        window.location.pathname === "/predict-scan"
      ) {
        context.setUser({
          ...context.user,
          // enrollImageData: faceLoginInputImageData,
        });
        navigateToNextPage();
        return;
      }
      if (["aadhaar", "digi-in"].includes(projectName)) {
        localStorage.setItem("user", JSON.stringify({ uuid: loginPUID } || {}));
        handleNextStep();
        return;
      }
      const data: any = await getUser(payload);
      if (data?.data?.level === ERROR || data?.data?.statusCode === 404) {
        context.setFailedMessage(AUTHENTICATION_FAILED);
        navigateWithQueryParams("/failed");
        context.setUser({
          ...context.user,
          data,
        });
        localStorage.setItem("user", JSON.stringify(data.user || {}));
        return;
      }
      const user =
        data?.user || JSON.parse(localStorage.getItem("user") ?? "{}");
      if (user._id) {
        const userStatus = getStatusFromUser(data.status);
        if (userStatus === SUCCESS) {
          context.setUser({
            ...context.user,
            ...user,
          });
          localStorage.setItem("user", JSON.stringify(user || {}));
          // switch (context.loginOption) {
          //   case "passkey":
          //     return pageConfig
          //       ? navigateToNextPage("", "/login-passkey", "/face-login")
          //       : navigateWithQueryParams("/login-passkey");
          //   case "loginPin":
          //     context?.setSuccessMessage("Authentication successful!");
          //     if (pageConfig) {
          //       navigateToNextPage("", "/verify-pin", "/face-login");
          //     } else {
          //       navigateWithQueryParams("/verify-pin");
          //     }
          //     break;
          //   case "loginDl":
          //     return pageConfig
          //       ? navigateToNextPage(
          //           "",
          //           "/drivers-licence-back-intro",
          //           "/face-login"
          //         )
          //       : navigateWithQueryParams("/drivers-licence-back-intro");
          //   case "loginVc":
          //     return pageConfig
          //       ? navigateToNextPage("", "/login-passkey", "/face-login")
          //       : navigateWithQueryParams("/login-passkey");
          //   case "checkoutVc":
          //     return onAuthenticatePasskey()
          //   case "accountRecovery":
          //     return pageConfig
          //       ? navigateToNextPage(
          //           "",
          //           "/drivers-licence-back-intro",
          //           "/face-login"
          //         )
          //       : navigateWithQueryParams("/drivers-licence-back-intro");
          //   case SECURITY_CHALLENGE:
          //     return pageConfig
          //       ? navigateToNextPage("", "/challenge", "/face-login")
          //       : navigateWithQueryParams("/challenge");
          //   default:
          //     localStorage.setItem("user", JSON.stringify(user || {}));
          //     context?.setSuccessMessage("Authentication successful!");
          //     if (projectName === "charlie") {
          //       navigateToNextPage("", "/store-os", "/face-login");
          //       return;
          //     }
          //     if (pageConfig) {
          //       navigateToNextPage("", "/success", "/face-login");
          //     } else {
          //       navigateWithQueryParams("/success");
          //     }
          //     break;
          // }
          handleNextStep();
        } else {
          context.setFailedMessage(ACCOUNT_NOT_APPROVED);
          navigateWithQueryParams("/failure");
        }
      }
    } else {
      context.setFailedMessage(AUTHENTICATION_FAILED);
      navigateWithQueryParams("/failed");
    }
  };

  const handleNextStep = () => {
    switch (context.loginOption) {
      case "passkey":
        return pageConfig
          ? navigateToNextPage("", "/login-passkey", "/face-login")
          : navigateWithQueryParams("/login-passkey");
      case "loginPin":
        context?.setSuccessMessage("Authentication successful!");
        if (pageConfig) {
          navigateToNextPage("", "/verify-pin", "/face-login");
        } else {
          navigateWithQueryParams("/verify-pin");
        }
        break;
      case "loginDl":
        return pageConfig
          ? navigateToNextPage("", "/drivers-licence-back-intro", "/face-login")
          : navigateWithQueryParams("/drivers-licence-back-intro");
      case "loginVc":
        return pageConfig
          ? navigateToNextPage("", "/login-passkey", "/face-login")
          : navigateWithQueryParams("/login-passkey");
      case "checkoutVc":
        return onAuthenticatePasskey();
      case "accountRecovery":
        return pageConfig
          ? navigateToNextPage("", "/drivers-licence-back-intro", "/face-login")
          : navigateWithQueryParams("/drivers-licence-back-intro");
      case SECURITY_CHALLENGE:
        return pageConfig
          ? navigateToNextPage("", "/challenge", "/face-login")
          : navigateWithQueryParams("/challenge");
      default:
        // localStorage.setItem("user", JSON.stringify(user || {}));
        context?.setSuccessMessage("Authentication successful!");
        if (projectName === "charlie") {
          navigateToNextPage("", "/store-os", "/face-login");
          return;
        }
        if (pageConfig) {
          navigateToNextPage("", "/success", "/face-login");
        } else {
          navigateWithQueryParams("/success");
        }
        break;
    }
  };

  useEffect(() => {
    if (loginGUID && loginPUID) {
      setCompleted(true);
    }
  }, [loginGUID, loginPUID]);

  const onCameraReady = () => {
    if (context?.loginOption === "face-login-mobile") return;
    faceLoginWithLiveness({ mf_token: "" });
  };

  return (
    <Layout removeHeight={!isCameraGranted}>
      <div className="px-10 py-8 max-md:p-[20px] max-md:pt-[20px]">
        <div className="flex justify-between relative max-md:p-0">
          <BackButton />
          <div className="bg-backgroundLightGray py-[5px] px-[15px] text-[12px] rounded-[20px] flex items-center m-auto">
            <img src={lock} alt="lock" className="mr-[5px]" />
            {pageConfig?.title || "Biometrics never leave this device"}
          </div>
        </div>
        <div className="mt-[50px] rounded-[20px] flex flex-col items-center justify-center relative">
          {context?.loginOption === "face-login-mobile" && showPhone && (
            <div className="absolute top-[15px] left-[20px] z-[99999] bg-[#0000006e] backdrop-blur-md h-[340px] max-md:top-[40px] max-md:h-[310px] w-[92%] rounded-[5px] flex items-center justify-center">
              <div className="relative w-[70%]">
                <div className="mt-[-20px]">
                  <PhoneInput
                    setValue={setPhone}
                    placeholder={"Phone Number"}
                    ariaLabel={"Phone Number"}
                  />
                </div>
                <div
                  onClick={() => {
                    setShowPhone(false);
                    faceLoginWithLiveness({ mf_token: "" });
                  }}
                  className="absolute right-0 top-0 bg-primary text-[#fff] w-[60px] h-[56px] rounded-[3px] flex justify-center items-center cursor-pointer text-[15px]"
                >
                  <Check />
                </div>
              </div>
            </div>
          )}
          <CameraComponent
            faceCamera={true}
            onCameraReady={onCameraReady}
            progress={progress}
            message={faceLoginWithLivenessMessage}
            onSuccess={onSuccess}
            attempt={typeof faceLoginResponseStatus === "number" ? 1 : 0}
            scanCompleted={completed}
            onCameraSwitch={() => faceLoginWithLiveness({ mf_token: "" })}
          />
        </div>
      </div>
    </Layout>
  );
}

export default MultiFaceLogin;
