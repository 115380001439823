import { createBrowserRouter } from "react-router-dom";

import Login from "common/authentication/login";
import Signup from "common/authentication/signup";
import FaceScan from "common/faceScan";
import PersonalInfo from "common/personalInfo";
import UserConsent from "common/userConsent";
import RedirectedMobile from "common/redirectScreens/RedirectedMobile";
import SwitchDevice from "common/redirectScreens/SwitchDevice";
import DocumentSelection from "common/documentSelection";
import DriversLicense from "common/documentSelection/driversLicenceIntro";
import VCProof from "common/vCProof";
import FaceScanningIntro from "common/faceScanningIntro";
import DriversLicenseIntro from "common/documentSelection/driversLicenceIntro";
import FrontDlScan from "common/documentSelection/frontDlScan";
import BackDlScan from "common/documentSelection/backDlScan";
import Success from "common/verifyingScreens/Success";
import CreatePasskey from "common/createPasskey";
import SSN from "common/additionalScreens/SSN";
import PassportIntro from "common/documentSelection/passportIntro";
import PassportScan from "common/documentSelection/passportScan";
import Waiting from "common/verifyingScreens/Waiting";
import FailureScreen from "common/verifyingScreens/failureScreens";
import NotApproved from "common/verifyingScreens/failureScreens/NotApproved";
import Address from "common/additionalScreens/address";
import FaceLogin from "common/loginScreens/faceLogin";
import MultiFaceLogin from "common/loginScreens/multiFaceLogin";
import LoginOptions from "common/loginScreens/loginOptions";
import LoginDl from "common/loginScreens/loginDl";
import LoginVC from "common/loginScreens/loginVc";
import LoginPasskey from "common/loginScreens/loginPasskey";
import IdentityConsent from "common/identityConsent";
import HhsConsent from "common/hhsConsent";
import VerifyPin from "common/verifyPin";
import Checkout from "common/checkout";
import LandingPage from "common/landingPage";
import Authorized from "common/authorized";
import ConfirmMobile from "common/confirmMobile";
import PredictAge from "common/predictAge";
import ContinuousAuthentication from "common/continuousAuthentication";
import ContinuousAuthenticationV2 from "common/continuousAuthenticationV2";
import Challenge from "common/challenge";
import MobileLogin from "common/mobileLogin";
import FaceLoginOtp from "common/loginOtp";
import CvsLanding from "common/cvsLanding";
import ReviewDetails from "common/reviewDetails";
import ElectronicSignature from "common/electronicSignature";
import WaitingInterview from "common/waitingInterview";
import InterviewCamera from "common/InterviewCamera";
import PdfViewer from "common/pdfViewer";
import StoreOs from "common/storeOS";
import CharmLanding from "common/charmLanding";
import ProveLogin from "common/proveLogin";
import ProveRegister from "common/proveRegister";
import CardScreen from "common/proveRegister/cardScreen";
import ProveLoginOtp from "common/proveLoginOtp";
import OtpPhone from "common/proveOtpSuccess";

export const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login isLogin={true} />,
  },
  {
    path: "/",
    element: <LandingPage />,
  },
  // LOGIN SCREEN
  {
    path: "/face-login",
    element: <FaceLogin />,
  },
  {
    path: "/multi-frame-login",
    element: <MultiFaceLogin />,
  },
  {
    path: "/login-options",
    element: <LoginOptions />,
  },
  {
    path: "/login-dl",
    element: <LoginDl />,
  },
  {
    path: "/login-vc",
    element: <LoginVC />,
  },
  {
    path: "/login-passkey",
    element: <LoginPasskey />,
  },
  // LOGIN SCREEN END
  {
    path: "/user-consent",
    element: <UserConsent />,
  },
  {
    path: "/user-info",
    element: <PersonalInfo />,
  },
  {
    path: "/switch-device",
    element: <SwitchDevice />,
  },
  {
    path: "/redirected-mobile",
    element: <RedirectedMobile />,
  },
  {
    path: "/face-scan-intro",
    element: <FaceScanningIntro />,
  },
  {
    path: "/face-scan",
    element: <FaceScan />,
  },
  {
    path: "/predict-age",
    element: <PredictAge />,
  },
  {
    path: "/doc-selection",
    element: <DocumentSelection />,
  },
  {
    path: "/drivers-licence-intro",
    element: <DriversLicenseIntro />,
  },
  {
    path: "/front-dl-scan",
    element: <FrontDlScan />,
  },
  {
    path: "/drivers-licence-back-intro",
    element: <DriversLicense documentSide="back" />,
  },
  {
    path: "/back-dl-scan",
    element: <BackDlScan />,
  },
  {
    path: "/passport-scan-intro",
    element: <PassportIntro />,
  },
  {
    path: "/passport-scan",
    element: <PassportScan />,
  },
  {
    path: "/generate-passkey",
    element: <CreatePasskey />,
  },
  {
    path: "/vc-proof",
    element: <VCProof />,
  },
  {
    path: "/address",
    element: <Address />,
  },
  {
    path: "/ssn",
    element: <SSN />,
  },
  {
    path: "/waiting",
    element: <Waiting />,
  },
  {
    path: "/identity-consent",
    element: <IdentityConsent />,
  },
  {
    path: "/verify-pin",
    element: <VerifyPin />,
  },
  {
    path: "/hhs-consent",
    element: <HhsConsent />,
  },
  {
    path: "/success",
    element: <Success heading={"Authentication successful!"} />,
  },
  {
    path: "/age-success",
    element: <Success heading={"Authentication successful!"} />,
  },
  {
    path: "/failed",
    element: (
      <NotApproved heading={"Authentication failed!"} showFooter={false} />
    ),
  },
  {
    path: "/failure",
    element: <FailureScreen buttonLabel="Rescan" heading="passport" />,
  },
  {
    path: "/checkout",
    element: <Checkout />,
  },
  {
    path: "/authorized",
    element: <Authorized />,
  },
  {
    path: "/passkey-success",
    element: <CreatePasskey />,
  },
  {
    path: "/confirm-mobile",
    element: <ConfirmMobile />,
  },
  {
    path: "/predict-scan-intro",
    element: <FaceScanningIntro />,
  },
  {
    path: "/predict-scan",
    element: <FaceLogin />,
  },
  {
    path: "/predict-success",
    element: <Success heading={"Authentication successful!"} />,
  },
  {
    path: "/enroll-success",
    element: <Success heading={"Authentication successful!"} />,
  },
  {
    path: "/predict-fail",
    element: <NotApproved heading={"Authentication failed!"} showFooter={false} />,
  },
  {
    path: "/continuous-authentication",
    element: <ContinuousAuthentication />,
  },
  {
    path: "/continuous-authentication-v2",
    element: <ContinuousAuthenticationV2 />,
  },
  {
    path: "/challenge",
    element: <Challenge />,
  },
  {
    path: "/face-login-otp",
    element: <FaceLoginOtp />,
  },
  {
    path: "/mobile-login",
    element: <MobileLogin />,
  },
  {
    path: "/cvs-landing",
    element: <CvsLanding />,
  },
  {
    path: "/review-details",
    element: <ReviewDetails />,
  },
  {
    path: "/electronic-signature",
    element: <ElectronicSignature />,
  },
  {
    path: "/waiting-interview",
    element: <WaitingInterview />,
  },
  {
    path: "/interview-camera",
    element: <InterviewCamera />,
  },
  {
    path: "/pdf-viewer",
    element: <PdfViewer />,
  },
  {
    path: "/store-os",
    element: <StoreOs />,
  },
  {
    path: "/charm-landing",
    element: <CharmLanding />,
  },
  {
    path: "/login-mobile",
    element: <ProveLogin />,
  },
  {
    path: "/register-mobile",
    element: <ProveRegister />,
  },
  {
    path: "/cards",
    element: <CardScreen />,
  },
  {
    path: "/login-mobile-otp",
    element: <ProveLoginOtp />,
  },
  {
    path: "/otp-phone",
    element: <OtpPhone />,
  },
  {
    path: "/register-mobile-with-id",
    element: <ProveRegister />,
  },
  {
    path: "/register-mobile-with-passport",
    element: <ProveRegister />,
  },
]);
