import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import { UserContext } from "context/userContext";
import { useContext, useEffect, useState } from "react";
import {
  verifyIdWithSession,
  verifySessionTokenV2,
} from "@privateid/cryptonets-web-sdk";
import PinInput from "react-pin-input";
import { isMobile, LoadPageConfiguration } from "utils";
import { useNavigation } from "utils/onNavigate";
import { issueCredentials } from "services/vc-dock";
import {
  generateRegistrationOptions,
  verifyRegistration,
} from "services/passkey";
import { startRegistration } from "@simplewebauthn/browser";
import { Loader2 } from "lucide-react";

type Props = {
  setStep: (e: number) => void;
};

const PhoneOtp = (props: Props) => {
  const { setStep } = props;
  const context: any = useContext(UserContext);
  const [disable, setDisable] = useState<boolean>(false);
  const { navigateToNextPage } = useNavigation();
  const [completed, setCompleted] = useState(false);
  const onRegister = async () => {
    if (window.location.pathname === "/register-mobile-with-passport") {
      onGeneratePasskey();
    } else {
      setCompleted(true);
    }
  };
  const [pin, setPin] = useState("");
  const [wrongPin, setWrongPin] = useState(false);
  const handlePinOnComplete = (value: string) => {
    setPin(value);
  };

  const onGeneratePasskey = async () => {
    setDisable(true);
    const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
    let response;

    try {
      response = await generateRegistrationOptions(uuid);
    } catch (error: any) {
      setDisable(false);
      setCompleted(true);
      return;
    }

    let attResp;
    try {
      const opts = response;
      attResp = await startRegistration(opts);
    } catch (error: any) {
      context.setSuccessMessage("Success! Your account is created");
      setDisable(false);
      setCompleted(true);
      return;
    }

    const verificationJSON = await verifyRegistration({ attResp, uuid });
    if (verificationJSON?.verified) {
      setDisable(false);
      setCompleted(true);
    }
  };

  useEffect(() => {
    if (completed) {
      LoadPageConfiguration(context, "/");
      setTimeout(() => {
        setStep(1);
        navigateToNextPage("", "/", "/register-mobile");
      }, 3000);
    }
  }, [completed]);

  const onVerify = async () => {
    await verifyIdWithSession({
      sessionToken: context?.tokenParams,
    });

    const verifyTokenRes = await verifySessionTokenV2({
      sessionToken: context?.tokenParams,
    });

    enum tokenStatus {
      PENDING = "PENDING",
      SUCCESS = "SUCCESS",
      FAILURE = "FAILURE",
      REQUIRES_INPUT = "REQUIRES_INPUT",
    }

    if (verifyTokenRes.status === tokenStatus.SUCCESS) {
      // Success
      context.setSuccessMessage("Success! Your account is created");
      await issueVC(verifyTokenRes.user, true);
    } else if (verifyTokenRes.status === tokenStatus.FAILURE) {
      context.setSuccessMessage("Success! Your account is created");
      await issueVC(verifyTokenRes.user, true);
    } else if (verifyTokenRes.status === tokenStatus.REQUIRES_INPUT) {
      // Handle requires input
    } else if (verifyTokenRes.status === tokenStatus.PENDING) {
      // Handle pending status
    }
  };

  const issueVC = async (userId: string, fullInformation: boolean) => {
    try {
      await issueCredentials(userId, fullInformation);
    } catch (e) {
      console.log({ e }, "error issueVC");
    }
  };

  useEffect(() => {
    if (window.location.pathname === "/register-mobile-with-passport") {
      onVerify();
    }
  }, []);

  return (
    <div
      className={`pt-[60px] bg-[#ffffff0a] h-full overflow-hidden ${
        completed ? "flex flex-col items-center justify-center !pt-[0px]" : ""
      }`}
    >
      {completed ? (
        <>
          <div className="bg-[#0e1026] w-[100%] h-[100%] flex items-center justify-center successAnimationNew">
            <div className="text-[25px] absolute top-[62px] left-[38px] z-[99999999] w-full bg-transparent bg-opacity-70 text-[#cbd4eb] text-left py-2 rounded-lg">
              Success
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="svg-success"
              viewBox="0 0 24 24"
            >
              <g
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
              >
                <circle
                  className="success-circle-outline"
                  cx="12"
                  cy="12"
                  r="11.5"
                />
                <circle
                  className="success-circle-fill"
                  cx="12"
                  cy="12"
                  r="11.5"
                />
                <polyline
                  className="success-tick"
                  points="17,8.5 9.5,15.5 7,13"
                />
              </g>
            </svg>
          </div>
        </>
      ) : (
        <>
          <Label className="text-[25px] font-[Google Sans] font-[500] text-[#cbd4eb] block text-center">
            Check your phone
          </Label>
          {/* <div className="flex justify-center mt-0 h-[260px]">
        <img src={loginFrame} alt="" width={500} />
      </div> */}
          <div className="flex flex-col w-[80%] m-auto max-md:w-[100%] max-md:p-[20px] mt-[20px]">
            <Label className="mt-[0px] text-[15px] font-[Google Sans] font-[100] text-[#cbd4eb]">
              A text message with a link was just sent to the phone ending in
              XXXX. Click on the link in the message to complete registration by
              entering the passcode below.
            </Label>
          </div>
          <div className="relative flex justify-center items-center pt-[30px]">
            <PinInput
              length={6}
              initialValue=""
              focus
              type="numeric"
              inputMode="number"
              style={{ padding: "10px" }}
              ariaLabel={`pin`}
              inputStyle={{
                borderColor: "#4d299e",
                width: isMobile ? "30px" : "45px",
                height: isMobile ? "30px" : "45px",
                fontSize: isMobile ? "20px" : "24px",
                marginLeft: 10,
                transition: "all .2s",
                color: "#fff",
              }}
              inputFocusStyle={{
                borderColor: "#814dfa",
                transform: "scale(1.1)",
              }}
              onChange={(e) => {
                setWrongPin(false);
              }}
              onComplete={(value) => {
                handlePinOnComplete(value);
              }}
              autoSelect={true}
              regexCriteria={/^[0-9]*$/}
            />
          </div>
          <div className="mt-[50px] flex justify-center max-md:mt-16">
            <Button
              className="w-[65%] h-[43px] bg-[#ffffff] rounded-[24px] text-[16px] text-[#000000] hover:opacity-90 hover:bg-[rgb(129,77,250)] hover:text-[#fff] transition-colors duration-500"
              onClick={() => onRegister()}
              disabled={disable || pin?.length !== 6}
            >
              {disable ? (
                <Loader2 className="animate-spin h-[20px] w-[20px] text-[rgb(129,77,250)] m-auto" />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
          <div
            className="mt-6 flex justify-center max-md:mt-2"
            onClick={() => onRegister()}
          >
            <Button className="w-[65%] underline bg-transparent rounded-[24px] text-[16px] hover:opacity-90 hover:bg-transparent border-2 border-transparent text-[#814dfa]">
              I did not receive the text
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default PhoneOtp;
